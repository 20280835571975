import {graphql} from 'gatsby'
import ContactSection from './../components/contact-section'
import HoldingPageHeroImage from './../components/holding-page-hero-image'
import Layout from './../components/layout'
import React, {useState} from 'react'

import {HOLDING_PAGE_CSS_MODIFIER, CONTACT_SECTION_CSS_MODIFIER, PAGE_ROOT_ELEMENT_ID} from './../constants/global-constants'

export default props => {
  const {data} = props
  const prismicData = {
    showTheHoldingPage: data.prismicHoldingPage.data.show_the_holding_page,
  }

  return (
    <Layout
      documentTitlePrefix="CONTACT"
      isIndexPage={false}
      pageName="contact"
      showScrollToTopBtn={false}
      showTheHoldingPage={prismicData.showTheHoldingPage}
    >
      {prismicData.showTheHoldingPage ? (
        <>
          <HoldingPageHeroImage />
          <ContactSection cssModifier={HOLDING_PAGE_CSS_MODIFIER} />
        </>
      ) : (
        <>
          <ContactSection cssModifier={CONTACT_SECTION_CSS_MODIFIER} />
        </>
      )}
    </Layout>
  )
}

export const pageQuery = graphql`
  {
    prismicHoldingPage {
      data {
        show_the_holding_page
      }
    }
  }
`
